import * as leaflet from 'leaflet';
import { icons, geo, style } from '../data';
import { UI } from '@lukaswagner/web-ui';
class Layer {
    _enabled = true;
    _data;
    _map;
    constructor(map, data) {
        this._map = map;
        this._data = data;
        if (this._data)
            this._data.addTo(this._map);
    }
    get data() {
        return this._data;
    }
    set data(v) {
        if (this._data && this._enabled)
            this._data.removeFrom(this._map);
        this._data = v;
        if (this._enabled)
            this._data.addTo(this._map);
    }
    set enabled(v) {
        if (this._enabled === v)
            return;
        this._enabled = v;
        if (this._data) {
            if (this._enabled)
                this._data.addTo(this._map);
            else
                this._data.removeFrom(this._map);
        }
    }
}
class Data {
    locations;
    characters;
    rivers;
    water;
    regions;
    route;
    constructor(_map) {
        this.locations = new Layer(_map);
        this.characters = new Layer(_map);
        this.rivers = new Layer(_map);
        this.water = new Layer(_map);
        this.regions = new Layer(_map);
        this.route = new Layer(_map);
    }
}
export class App {
    _map;
    _data;
    _date;
    static _prioMarkerPane = 'prioMarkerPane';
    constructor() {
        this._map = leaflet.map('map');
        this._data = new Data(this._map);
        this.initMap();
        this.createDateUI();
        this._map.fitBounds(this._data.route.data.getBounds());
    }
    initMap() {
        const tileSet = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
        const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
        const tileLayer = leaflet.tileLayer(tileSet, { attribution });
        tileLayer.addTo(this._map);
        const pane = this._map.createPane(App._prioMarkerPane);
        pane.style.zIndex = '601';
    }
    updateLocations() {
        const filter = this.filterByDate.bind(this);
        const locationConf = {
            pointToLayer: App.createMarker,
            onEachFeature: App.createMarkerLabel,
            filter: (v) => !App.isCharacter(v) && filter(v)
        };
        this._data.locations.data = leaflet.geoJson(geo.locations, locationConf);
        const characterConf = {
            pointToLayer: App.createMarker,
            onEachFeature: App.createMarkerLabel,
            filter: (v) => App.isCharacter(v) && filter(v)
        };
        this._data.characters.data = leaflet.geoJson(geo.locations, characterConf);
        const riversConf = { onEachFeature: App.createFeatureLabel, filter, style: style.rivers };
        this._data.rivers.data = leaflet.geoJson(geo.rivers, riversConf);
        const waterConf = { onEachFeature: App.createFeatureLabel, filter, style: style.water };
        this._data.water.data = leaflet.geoJson(geo.water, waterConf);
        const regionsConf = { onEachFeature: App.createFeatureLabel, filter, style: style.regions };
        this._data.regions.data = leaflet.geoJson(geo.regions, regionsConf);
        const routeConf = { onEachFeature: App.createRouteLabel, filter, style: style.route };
        this._data.route.data = leaflet.geoJson(geo.route, routeConf);
    }
    static createMarker(feature, pos) {
        if (App.isCharacter(feature)) {
            return leaflet.marker(pos, {
                icon: icons.person, pane: App._prioMarkerPane
            });
        }
        return leaflet.marker(pos, { icon: icons.location });
    }
    static createMarkerLabel(feature, layer) {
        const props = feature.properties;
        let popup = `${props.date}. Dezember`;
        if (props.year)
            popup += `<br>${props.year}`;
        if (props.location)
            popup += `<br>${props.location}`;
        if (props.person)
            popup += `<br>${props.person}`;
        if (props.sheep)
            popup += `<br>${props.sheep}`;
        if (props.angel)
            popup += `<br>${props.angel}`;
        layer.bindPopup(popup);
    }
    static createFeatureLabel(feature, layer) {
        const props = feature.properties;
        const popup = `${props.date}. Dezember<br>${props.name}`;
        layer.bindPopup(popup);
    }
    static createRouteLabel(feature, layer) {
        const props = feature.properties;
        const popup = `${props.date}. Dezember`;
        layer.bindPopup(popup);
    }
    filterByDate(feature) {
        const props = feature.properties;
        return props.date <= this._date;
    }
    static isCharacter(feature) {
        const props = feature.properties;
        return !!(props.person || props.sheep || props.angel);
    }
    createDateUI() {
        const MyUI = leaflet.Control.extend({
            onAdd: (_) => {
                const div = leaflet.DomUtil.create('div', 'date-ui');
                leaflet.DomEvent.disableClickPropagation(div);
                const ui = new UI(div, true);
                ui.input.checkbox({
                    label: 'Route',
                    value: true,
                    handler: (v) => this._data.route.enabled = v
                });
                const waypoints = ui.input.checkbox({
                    label: 'Wegpunkte',
                    value: true,
                    handler: (v) => {
                        chars.elements[0].disabled = !v;
                        if (v)
                            chars.container.classList.remove('disabled');
                        else
                            chars.container.classList.add('disabled');
                        this._data.locations.enabled = v && !chars.value;
                        this._data.characters.enabled = v;
                    },
                    handleOnInit: false
                });
                const chars = ui.input.checkbox({
                    label: 'Nur neue Pilger',
                    value: false,
                    handler: (v) => {
                        this._data.locations.enabled = !v && waypoints.value;
                    },
                });
                chars.container.classList.add('indented');
                waypoints.invokeHandler();
                ui.input.checkbox({
                    label: 'Flüsse',
                    value: true,
                    handler: (v) => this._data.rivers.enabled = v
                });
                ui.input.checkbox({
                    label: 'Meere/Seen',
                    value: false,
                    handler: (v) => this._data.water.enabled = v
                });
                ui.input.checkbox({
                    label: 'Regionen',
                    value: false,
                    handler: (v) => this._data.regions.enabled = v
                });
                ui.input.numberRange({
                    label: 'Datum',
                    min: 1,
                    max: 24,
                    step: 1,
                    value: App.getDefaultDate(),
                    handler: (v) => {
                        this._date = v;
                        this.updateLocations();
                    },
                    triggerHandlerOnMove: true,
                });
                return div;
            },
        });
        const ui = new MyUI({ position: 'bottomleft' });
        ui.addTo(this._map);
    }
    static getDefaultDate() {
        const now = new Date();
        switch (now.getMonth()) {
            case 10: // november
                // only first day visible
                return 1;
            case 11: // december
                // use actual date
                return now.getDate();
            default: // januar - october
                // everything visible
                return 24;
        }
    }
}
