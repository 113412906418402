import * as leaflet from 'leaflet';
const pinActualSize = 127;
const pinActualTip = 123;
const pinTargetSize = 31;
const pinTargetTip = pinActualTip / pinActualSize * pinTargetSize;
const icons = {
    location: leaflet.icon({
        iconUrl: require('./marker/pin_grey.png'),
        iconSize: [pinTargetSize, pinTargetSize],
        iconAnchor: [(pinTargetSize - 1) / 2, pinTargetTip],
        popupAnchor: [0, -pinTargetTip]
    }),
    person: leaflet.icon({
        iconUrl: require('./marker/person.png'),
        iconSize: [pinTargetSize, pinTargetSize],
        iconAnchor: [(pinTargetSize - 1) / 2, pinTargetTip],
        popupAnchor: [0, -pinTargetTip]
    })
};
const geo = {
    locations: require('./locations/locations.geojson'),
    rivers: require('./locations/rivers.geojson'),
    water: require('./locations/water.geojson'),
    regions: require('./locations/regions.geojson'),
    route: require('./locations/route.geojson')
};
const style = {
    rivers: { color: '#3333ff', opacity: 0.75 },
    water: { color: '#3333ff', opacity: 0.5, fillOpacity: 0.2 },
    regions: { color: '#33aa33', opacity: 0.5, fillOpacity: 0.2 },
    route: { color: '#000000', opacity: 0.75, dashArray: '10' },
};
export { icons, geo, style };
